import { categories } from "../Data/all_products_data";
import { data } from "../Data/all_products_data";
import AllProductsLock from "../Components/AllProductsLock";
import { IoSearchOutline } from "react-icons/io5";
import { useSearchParams } from "react-router-dom";

export default function AllProducts() {
  const [searchParams, setSearchParams] = useSearchParams();

  const typeFilter = searchParams.get("filter");
  const searchFilter = searchParams.get("search");

  const displayedData = typeFilter
    ? data.locks.filter((lock) => {
        return lock.category.toLowerCase() + "s" === typeFilter ? lock : null;
      })
    : data.locks;
  return (
    <div className="all-products-main">
      <h2>From Shutter Locks to Door Locks – We've Got It All!</h2>
      <div className="search-all-products">
        <input
          placeholder="Search Door7 Locks"
          value={searchFilter ? searchFilter : ""}
          onChange={(e) => {
            setSearchParams({ search: e.target.value });
          }}
          className="search-input"
          name="search-input"
        />
        <IoSearchOutline
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            color: "#787878",
            fontSize: "22px",
          }}
        />
      </div>
      <div className="all-products-container">
        <div className="all-products-filter-sidebar">
          <h4>Filters</h4>
          {categories.map((category) => (
            <div className="cateory-el" key={category}>
              <button
                className="filter-btn"
                onClick={() =>
                  setSearchParams({ filter: category.toLowerCase() })
                }
              >
                {category}
              </button>
            </div>
          ))}
          <button className="tertiary-btn" onClick={() => setSearchParams({})}>
            Clear Filters
          </button>
        </div>
        <div className="all-products">
          {displayedData.map((lock, idx) =>
            searchFilter === null ? (
              <AllProductsLock lock={lock} key={lock.id} />
            ) : lock.name.toLowerCase().includes(searchFilter.toLowerCase()) ? (
              <AllProductsLock lock={lock} key={lock.id} />
            ) : null
          )}
        </div>
      </div>
    </div>
  );
}
