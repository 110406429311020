export const data = {
  locks: [
    {
      id: 1,
      name: "Cylindrical Door Lock With Brass Fitting",
      img: "https://ik.imagekit.io/door7locks/Images/Home/cylindrical%20door%20lock%20with%20brass%20fitting.png",
    },
    {
      id: 2,
      name: "Tri Bolt Both Side Key",
      img: "https://ik.imagekit.io/door7locks/Images/Home/tri%20bolt%20both%20side%20key.png",
    },
    {
      id: 3,
      name: "100MM Door Lock Heavy (Only Locking)",
      img: "https://ik.imagekit.io/door7locks/Images/Home/100mm%20door%20lock%20only%20locking%20heavy.png",
    },
    {
      id: 4,
      name: "Side Shutter Lock With Master Key",
      img: "https://ik.imagekit.io/door7locks/Images/Home/side%20shutter%20lock%20with%20master%20key.png",
    },
    {
      id: 5,
      name: "Center Shutter Lock Heavy",
      img: "https://ik.imagekit.io/door7locks/Images/Home/center%20shutter%20lock%20heavy.png",
    },
  ],
};
