import { useEffect, useRef, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function CTAButton({ text, arrowOn, nav, hover }) {
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const buttonRef = useRef();

  useEffect(() => {
    if (hover) {
      if (isHovered) {
        buttonRef.current.className = "CTA-btn CTA-btn-hover";
      } else {
        buttonRef.current.className = "CTA-btn";
      }
    }
  }, [isHovered]);

  return (
    <button
      className="CTA-btn"
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      onClick={() => {
        navigate(nav);
      }}
      ref={buttonRef}
    >
      {text} {arrowOn && <FaArrowRight className="arrow" />}
    </button>
  );
}
