import { NavLink } from "react-router-dom";
import CTAButton from "./CTAButton";
import Hamburger from "./Hamburger";
import { useState } from "react";

export default function Navbar() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  function toggleHamburger() {
    setHamburgerOpen((prevState) => !prevState);
  }
  return (
    <nav>
      <NavLink to=".">
        <img src="Images/door7-logo.svg" alt="Door7 Locks Logo" />
      </NavLink>

      <div className="hamburger-div" onClick={toggleHamburger}>
        <Hamburger isOpen={hamburgerOpen} toggle={toggleHamburger} />
      </div>

      <div className="nav-btns">
        <NavLink to="about">About</NavLink>
        <NavLink to="contact">Contact</NavLink>
        <NavLink
          to={require("../pdf-app/Door7LocksCatalog.pdf")}
          target="_blank"
          rel="noopener noreferrer"
          //   download
        >
          Catalog
        </NavLink>

        <CTAButton text="Browse Products" nav="allProducts" hover={true} />
      </div>
    </nav>
  );
}
