import { useState } from "react";
import CTAButton from "../Components/CTAButton";
import { MdArrowOutward } from "react-icons/md";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevData) => {
      return { ...prevData, [name]: value };
    });
  };
  return (
    <div className="contact-page-main">
      <h1>Get In Touch</h1>
      <div className="contact-page-grid">
        <div className="contact-page-content">
          <h4>Don’t hesitate to drop by and say a hello!</h4>
          <div className="contact-content-field">
            <span>Phone</span>
            <h5>+91 9837052761</h5>
          </div>
          <div className="contact-content-field">
            <span>Email</span>
            <h5>dvarshney232@gmail.com</h5>
          </div>
          <div className="contact-content-field">
            <span>Address</span>
            <h5>
              Near Ashish Vatika Lodge, Shivraj Nagar, Gular Road, Aligarh,
              Uttar Pradesh, India- 202001
            </h5>
            <button className="tertiary-btn view-address-btn">
              <a
                href="https://www.google.com/maps/place/DPC+locks+manufacturing+and+casters/@27.889505,78.0608488,15z/data=!4m6!3m5!1s0x3974a586bac685d7:0xcdaf000883de22ae!8m2!3d27.889505!4d78.0608488!16s%2Fg%2F11fjzvt32q?entry=ttu&g_ep=EgoyMDI0MTAyNy4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noreferrer"
              >
                View On Google Maps <MdArrowOutward />
              </a>
            </button>
          </div>
        </div>
        <form>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            autoComplete="on"
            className="contact-form-input"
            placeholder="Name"
          />
          <hr />
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            autoComplete="on"
            className="contact-form-input"
            placeholder="Email"
          />
          <hr />
          <textarea
            type="text"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="contact-form-input contact-form-textarea"
            placeholder="Message"
          />
          <CTAButton text="Send" nav="." hover={false} />
        </form>
      </div>
    </div>
  );
}
