import CTAButton from "../Components/CTAButton";
import Slider from "react-slick";
import { data } from "../Data/home_data";
import { Link } from "react-router-dom";

export default function Home() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    className: "center",
    centerMode: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="home-main">
      <div className="home-content">
        <h1>Open the door to safety with door7 locks</h1>
        <p>India’s safest lock selling and manufacturing company</p>

        <CTAButton
          text="Browse Products"
          arrowOn={true}
          nav="allProducts"
          hover={true}
        />
      </div>

      <Slider {...settings}>
        {data.locks.map((lock, index) => {
          return (
            <div
              className="slider-element"
              // style={{ width: "fit-content" }}
              key={lock.id}
            >
              <h4>{lock.name}</h4>
              <div className="slider-image">
                <img src={`${lock.img}`} alt={lock.name} />
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
