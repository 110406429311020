import { useState } from "react";
import CTAButton from "./CTAButton";

export default function AllProductsLock({ lock }) {
  const [selectedImgId, setSelectedImgId] = useState(1);

  return (
    <div className="lock" key={lock.id}>
      <div className="lock-details">
        {lock.images.map(
          (image) =>
            image.id === selectedImgId && (
              <img
                src={image.src}
                alt={lock.name}
                className="all-products-lock-main-img"
                key={image.id}
              />
            )
        )}
        <div className="lock-image-div">
          {lock.images.map((image) => (
            <div
              className="lock-image-div-img"
              onClick={() => {
                setSelectedImgId(image.id);
              }}
              key={image.id}
            >
              <img
                src={image.src}
                alt={`${lock.name}-${image}`}
                className={
                  image.id === selectedImgId
                    ? "lock-image-div-img selected"
                    : "lock-image-div-img"
                }
              />
            </div>
          ))}
        </div>

        <h4 className="all-products-lock-name">{lock.name}</h4>
      </div>
      <CTAButton text="Inquire Now" nav="/contact" hover={true} />
    </div>
  );
}
