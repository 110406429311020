import MainLayout from "./Layouts/MainLayout";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import AllProducts from "./Pages/AllProducts";

function App() {
  return (
    <>
      <div className="bg-top-triangle"></div>
      <div className="App">
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="allProducts" element={<AllProducts />} />
          </Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
