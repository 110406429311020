export const data = {
  locks: [
    {
      id: 1,
      name: "Cylindrical Door Lock With Brass Fitting",
      category: "Door Lock",
      model: "Sparta",
      code: "0520",
      keys: "Ultra",
      action: "Dual Action",
      finish: [
        "Stainless Steal (S.S.)",
        "Powder coating (P.C.)",
        "Brass Antique (Ant.)",
      ],

      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/cylindrical%20door%20lock%20with%20brass%20fitting/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/cylindrical%20door%20lock%20with%20brass%20fitting/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/cylindrical%20door%20lock%20with%20brass%20fitting/item%201.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/cylindrical%20door%20lock%20with%20brass%20fitting/item%202.png",
          isSelected: false,
        },
        {
          id: 5,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/cylindrical%20door%20lock%20with%20brass%20fitting/item%203.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 2,
      name: "Door Lock In Brass Plating",
      category: "Door Lock",
      model: "Super",
      code: "340",
      keys: 3,
      action: "Dual Action",
      finish: ["Powder coating (P.C.)", "Brass Antique (Ant.)"],
      lever: 8,
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20in%20brass%20plating/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20in%20brass%20plating/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20in%20brass%20plating/item%201.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20in%20brass%20plating/item%202.png",
          isSelected: false,
        },
        {
          id: 5,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20in%20brass%20plating/item%203.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 3,
      name: "Door Lock With Iron Fitting",
      category: "Door Lock",
      model: "Sparta",
      code: "0610",
      keys: 3,
      action: "Dual Action",
      finish: ["Stainless Steal (S.S.)"],
      lever: 8,
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20with%20iron%20fitting/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20with%20iron%20fitting/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20with%20iron%20fitting/item%201.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20with%20iron%20fitting/item%202.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 4,
      name: "100mm Door Lock Heavy 2 in 1",
      category: "Door Lock",
      model: "Sabu",
      code: "0610",
      keys: 3,
      action: "Dual Action",
      size: "100mm",
      finish: ["Powder coating (P.C.)"],
      lever: 6,
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%202%20in%201/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%202%20in%201/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%202%20in%201/item%201.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%202%20in%201/item%202.png",
          isSelected: false,
        },
        {
          id: 5,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%202%20in%201/item%203.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 5,
      name: "100MM Door Lock Heavy (Only Locking)",
      category: "Door Lock",
      model: "Sabu 02",
      code: "0430",
      keys: 3,
      action: "Dual Action",
      finish: ["Powder coating (P.C.)"],
      lever: 6,
      size: "4 inch",
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%20(only%20locking)/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%20(only%20locking)/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%20(only%20locking)/item%201.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%20(only%20locking)/item%202.png",
          isSelected: false,
        },
        {
          id: 5,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/100mm%20door%20lock%20heavy%20(only%20locking)/item%203.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 6,
      name: "125MM Door Lock Heavy",
      category: "Door Lock",
      model: "Soldier",
      code: "01213",
      keys: 3,
      action: "Dual Action",
      finish: ["Powder coating (P.C.)"],
      lever: 6,
      size: "5 inch",
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/125mm%20door%20lock%20heavy/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/125mm%20door%20lock%20heavy/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/125mm%20door%20lock%20heavy/item%201.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/125mm%20door%20lock%20heavy/item%202.png",
          isSelected: false,
        },
        {
          id: 5,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/125mm%20door%20lock%20heavy/item%203.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 7,
      name: "Side Shutter Lock With Master Key",
      category: "Shutter Lock",
      model: "Jumbo",
      code: "93940",
      keys: 3,
      action: "Dual Action",
      finish: ["Powder coating (P.C.)"],
      lever: 8,
      size: "200mm",
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/side%20shutter%20lock%20with%20master%20key/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/side%20shutter%20lock%20with%20master%20key/item%201.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 8,
      name: "Side Shutter Lock",
      category: "Shutter Lock",
      model: "Commander",
      code: "03202",
      keys: "3 Masterkeys",
      action: "Triple Action",
      finish: ["Powder coating (P.C.)"],
      lever: 8,
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/side%20shutter%20lock/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/side%20shutter%20lock/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/side%20shutter%20lock/item%201.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 9,
      name: "Tri Bolt Both Side Key",
      category: "Tri Bolt",
      model: "Tri Bolt",
      code: "01312 A",
      keys: "4 Ultra Key with 1 Light Key",
      action: "Dual Action",
      finish: ["Stainless Steal (S.S.)", "Brass Antique (Ant.)"],
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20both%20side%20key/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20both%20side%20key/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20both%20side%20key/item%201.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20both%20side%20key/item%202.png",
          isSelected: false,
        },
        {
          id: 5,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20both%20side%20key/item%203.png",
          isSelected: false,
        },
        {
          id: 6,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20both%20side%20key/item%204.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 10,
      name: "Tri Bolt One Side Key",
      category: "Tri Bolt",
      model: "Tri Bolt",
      code: "01312 B",
      keys: "4 Ultra Key with 1 Light Key",
      action: "Dual Action",
      finish: ["Stainless Steal (S.S.)", "Brass Antique (Ant.)"],
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20one%20side%20key/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20one%20side%20key/item%201.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20one%20side%20key/item%202.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/tri%20bolt%20one%20side%20key/item%203.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 11,
      name: "Door Lock (Also available in Brass Fitting)",
      category: "Door Lock",
      model: "Spark",
      code: "03040",
      keys: 3,
      action: "Dual Action",
      finish: ["Brass Antique (Ant.)"],
      speciality: ["With Prime Lequre"],
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20(also%20available%20in%20brass%20fitting)/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20(also%20available%20in%20brass%20fitting)/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20(also%20available%20in%20brass%20fitting)/item%201.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20(also%20available%20in%20brass%20fitting)/item%202.png",
          isSelected: false,
        },
        {
          id: 5,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20(also%20available%20in%20brass%20fitting)/item%203.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 12,
      name: "Door Lock ( Also available in Brass Fitting )",
      category: "Door Lock",
      model: "Bullet",
      code: "06100",
      bulletSize: "15mm",
      lever: 8,
      keys: 3,
      action: "Dual Action",
      finish: ["Powder coating (P.C.)"],
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20(%20also%20available%20in%20brass%20fitting%20)/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20(%20also%20available%20in%20brass%20fitting%20)/item%201.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20(%20also%20available%20in%20brass%20fitting%20)/item%202.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/door%20lock%20(%20also%20available%20in%20brass%20fitting%20)/item%203.png",
          isSelected: false,
        },
      ],
    },
    {
      id: 13,
      name: "Center Shutter Lock Heavy",
      category: "Shutter Lock",
      model: "HERCULES",
      code: "01411",
      keys: 3,
      action: "Dual Action",
      finish: ["Powder coating (P.C.)"],
      //   images: ["image 1", "image 2", "item 1", "item 2", "item 3"],
      images: [
        {
          id: 1,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/center%20shutter%20lock%20heavy/image%201.png",
          isSelected: true,
        },
        {
          id: 2,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/center%20shutter%20lock%20heavy/image%202.png",
          isSelected: false,
        },
        {
          id: 3,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/center%20shutter%20lock%20heavy/item%201.png",
          isSelected: false,
        },
        {
          id: 4,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/center%20shutter%20lock%20heavy/item%202.png",
          isSelected: false,
        },
        {
          id: 5,
          src: "https://ik.imagekit.io/door7locks/Images/All%20Products/center%20shutter%20lock%20heavy/item%203.png",
          isSelected: false,
        },
      ],
    },
  ],
};

export const categories = ["Door Locks", "Shutter Locks", "Tri Bolts"];
